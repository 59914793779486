@import "../../variables";

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .titleBlock {
    background-color: #eff5ff;
    box-shadow: 0 5px 9px #ccc;
    .titleContainer {
      display: flex;
      align-items: center;
      .iconContainer {
        background-color: #fff;
        padding: 10px;
        box-shadow: 0px 2px 10px #ccc;
        border-radius: $basic-border-radius;
      }
      .title {
        margin: 0 0 0 20px;
        padding: 20px 0;
      }
    }
  }
  .container, .emptyContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .container {
    margin: 25px auto;
  }
}