@import "../variables";

.login {
  background: center no-repeat url("/images/other/troupeau-girafes.jpg");
  background-size: cover;
}

.loginVertical {
  display: flex;
  justify-content: center;
}

.loginContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #2ee7ff 0%, #4dffde 100%);
  max-width: 540px;
  .paper {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 540px;
    padding: 30px;
  }
  .loginForm {
    padding: 25px;
    > h1 {
      text-align: center;
    }
  }
}
.avatar {
  margin: 4px;
}
.form {
  width: 100%;
  margin-top: 4px;
}
.submit {
  margin: 6px 0 2px;
}
.forgotPasswordSuccessContainer {
  padding: 15px 10px;
  color: #ffffff;
  background-color: $success-color;
  margin: 15px 0;
  border-radius: $basic-border-radius;
}